import React from 'react';
import styled from 'styled-components';
import googlePlayBadge from '../assets/googleplay.png';
import appStoreBadge from '../assets/appstore.png';

const AppLinksContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0;
  box-sizing: border-box;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
  }
`;

const AppLink = styled.a`
  display: block;
  margin: 0;
  padding: 0;
  text-decoration: none; /* Remove default underline */
  outline: none;
  border: none;
  box-shadow: none;

  @media (max-width: 480px) {
    margin-bottom: 5px; /* Reduce margin between app links */

    &:last-child {
      margin-bottom: 0; 
    }
  }

  &:focus {
    outline: none; /* Remove default focus outline */
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); /* Custom focus outline */
  }
`;

const AppLinkImage = styled.img`
  width: 200px;
  height: auto;
  display: block;
  margin: 0;
  padding: 10px;
  border: none;

  @media (max-width: 768px) {
    width: 200px;
  }
  @media (max-width: 480px) {
    width: 200px;
  }
`;

const AppLinks = () => {
  return (
    <AppLinksContainer>
      {/* <AppLink href="https://apps.apple.com/your-app-link" target="_blank" rel="noopener noreferrer">
        <AppLinkImage src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83" alt="Download on the App Store" />
      </AppLink>
      <AppLink href="https://play.google.com/store/apps/details?id=your.package.name" target="_blank" rel="noopener noreferrer">
        <AppLinkImage src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" alt="Get it on Google Play" />
      </AppLink> */}
      <AppLink href="https://apps.apple.com/us/app/doc-minder/id6502924570">
        <AppLinkImage src={appStoreBadge} alt="Download on the App Store" />
      </AppLink>
      <AppLink href="https://play.google.com/store/apps/details?id=com.docminder.docminder&pli=1">
        <AppLinkImage src={googlePlayBadge} alt="Get it on Google Play" />
      </AppLink>
    </AppLinksContainer>
  );
};

export default AppLinks;
