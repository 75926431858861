import React from 'react';
import styled from 'styled-components';
import { FaFileAlt, FaBell, FaLock, FaBook } from 'react-icons/fa';

const FeaturesSection = styled.section`
  padding: 60px 20px;
  background-color: #f4f6f8;
  text-align: center;
`;

const FeaturesTitle = styled.h2`
  font-size: 2.5em;
  margin-bottom: 40px;
  color: #2c3e50;
  position: relative;
  display: inline-block;
  &::after {
    content: '';
    width: 50px;
    height: 4px;
    background-color: #3498db;
    display: block;
    margin: 10px auto 0;
    border-radius: 2px;
  }
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  justify-content: center;
`;

const FeatureCard = styled.div`
  background: white;
  padding: 30px 20px;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: rgb(64, 120, 133);
//   background: linear-gradient(90deg, rgba(53,180,251,1) 0%, rgba(99,199,255,1) 35%, rgba(164,223,255,1) 100%);
  color: white;
  overflow: hidden;
  position: relative;
  z-index: 0;
  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: rgba(255, 255, 255, 0.1);
    transition: all 0.4s;
    transform: rotate(30deg);
    z-index: -1;
  }
`;

const FeatureIcon = styled.div`
  font-size: 60px;
  margin-bottom: 20px;
`;

const FeatureTitle = styled.h3`
  font-size: 1.8em;
  margin-bottom: 15px;
  color: white;
`;

const FeatureDescription = styled.p`
  font-size: 1em;
  color: white;
`;

const Features = () => {
  return (
    <FeaturesSection id="features">
      <FeaturesTitle>Features</FeaturesTitle>
      <FeaturesGrid>
        <FeatureCard>
          <FeatureIcon><FaFileAlt /></FeatureIcon>
          <FeatureTitle>Document Management</FeatureTitle>
          <FeatureDescription>
            Easily upload and manage your important documents with images, notes, and expiration dates.
          </FeatureDescription>
        </FeatureCard>
        <FeatureCard>
          <FeatureIcon><FaBell /></FeatureIcon>
          <FeatureTitle>Timely Notifications</FeatureTitle>
          <FeatureDescription>
            Stay ahead with customizable email and push notification reminders for 3, 5, 15, 30, and 60 days before expiration.
          </FeatureDescription>
        </FeatureCard>
        <FeatureCard>
          <FeatureIcon><FaLock /></FeatureIcon>
          <FeatureTitle>Secure Storage</FeatureTitle>
          <FeatureDescription>
            Your documents are encrypted and stored securely to ensure privacy and protection.
          </FeatureDescription>
        </FeatureCard>
        <FeatureCard>
          <FeatureIcon><FaBook /></FeatureIcon>
          <FeatureTitle>Continuing Education</FeatureTitle>
          <FeatureDescription>
            Manage Continuing Education documents with ease, including sub-documents with images, hours, and dates.
          </FeatureDescription>
        </FeatureCard>
      </FeaturesGrid>
    </FeaturesSection>
  );
};

export default Features;
