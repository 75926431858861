import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 60px 20px;
  background: #fff;
  max-width: 800px;
  margin: auto;
  flex: 1;

  h1 {
    text-align: center;
    color: #2c3e50;
    margin-bottom: 40px;
  }

  h3 {
    color: #2c3e50;
    margin-bottom: 15px;
  }

  p, ul {
    line-height: 1.6;
    margin-bottom: 20px;
    color: #2c3e50;
  }

  ul {
    padding-left: 20px;
    margin-bottom: 25px;
    color: #2c3e50;
  }

  li {
    // display: flex;
  }

  strong {
    margin-right: 5px;
  }
`;

const PrivacyPolicy = () => {
  return (
    <Container>
      <h1>Privacy Policy</h1>
      <p>Last updated: June 13, 2024</p>
      <p>This Privacy Policy describes Our policies and procedures on the collection, use, and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
      <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.</p>

      <h3>Interpretation and Definitions</h3>
      <p><strong>Interpretation</strong></p>
      <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>

      <h3>Definitions</h3>
      <ul>
        <li><strong>Account:</strong> means a unique account created for You to access our Service or parts of our Service.</li>
        <li><strong>Affiliate:</strong> means an entity that controls, is controlled by, or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest, or other securities entitled to vote for election of directors or other managing authority.</li>
        <li><strong>Application:</strong> refers to Doc Minder, the software program provided by the Company.</li>
        <li><strong>Company:</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to 64bits LLC.</li>
        <li><strong>Country:</strong> refers to Texas, United States.</li>
        <li><strong>Device:</strong> means any device that can access the Service such as a computer, a cellphone, or a digital tablet.</li>
        <li><strong>Personal Data:</strong> is any information that relates to an identified or identifiable individual.</li>
        <li><strong>Service:</strong> refers to the Application.</li>
        <li><strong>Service Provider:</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service, or to assist the Company in analyzing how the Service is used.</li>
        <li><strong>Usage Data:</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>
        <li><strong>You:</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
      </ul>

      <h3>Information Collection and Use</h3>
      <p>We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. The types of personal information we may collect include:</p>
      <ul>
        <li>Personal Identification Information: Full name, email address, password.</li>
        <li>Document Information: Uploaded documents with images, notes, expiration dates, and notification settings.</li>
        <li>Continuing Education Documents: Sub-documents with images, hours, and dates if you select this document type.</li>
        <li>Usage Data: How you interact with our website and services.</li>
        <li>Cookies and Tracking Technologies: To enhance your user experience and for analytics purposes.</li>
      </ul>

      <h3>Data Retention and Protection</h3>
      <p>We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use, or modification.</p>
      <p>Our data protection measures include:</p>
      <ul>
        <li>Encryption of sensitive information.</li>
        <li>Regularly updating our security practices and technologies.</li>
        <li>Restricting access to personal information to authorized personnel only.</li>
      </ul>

      <h3>Notifications</h3>
      <p>Users will receive email and push notifications based on the notification settings (3, 5, 15, 30, and 60 days prior to expiration). These notifications are an integral part of the service we provide.</p>

      <h3>Third-Party Sharing</h3>
      <p>We don’t share any personally identifying information publicly or with third parties, except when required to by law.</p>
      <p>We may share your information with:</p>
      <ul>
        <li>Service providers who assist us in operating our website and services.</li>
        <li>Legal authorities if required by law or to protect our legal rights.</li>
      </ul>

      <h3>External Links</h3>
      <p>Our website and application may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.</p>
      <p>We encourage you to review the privacy policies of any external sites you visit.</p>

      <h3>Your Rights</h3>
      <p>You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.</p>
      <p>You have the right to:</p>
      <ul>
        <li>Access and obtain a copy of your personal data.</li>
        <li>Request correction of any inaccurate personal data.</li>
        <li>Request the deletion of your personal data.</li>
      </ul>

      <h3>Acceptance of Terms</h3>
      <p>Your continued use of our website and application will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.</p>

      <h3>Changes to This Policy</h3>
      <p>We may update our privacy policy from time to time to reflect changes to our practices or for other operational, legal, or regulatory reasons. We will notify you of any changes by posting the new privacy policy on this page. You are advised to review this privacy policy periodically for any changes.</p>

      <h3>Contact Us</h3>
      <p>If you have any questions or concerns about our privacy policy, please contact us at:</p>
      <p><strong>Email:</strong> hello@doc-minder.com</p>

      <h3>Effective Date</h3>
      <p>This policy is effective as of June 13, 2024.</p>
    </Container>
  );
};

export default PrivacyPolicy;
