import React from 'react';
import styled from 'styled-components';
import AppLinks from './AppLinks';

const HeroSection = styled.section`
  background: rgb(207, 225, 232);
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 20px;
  box-sizing: border-box;
  min-width: 300px; /* Ensure minimum width for each section */
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const HeroContainer = styled.div`
  max-width: 600px;
  text-align: center;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const HeroTitle = styled.h2`
  font-size: 2em;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    padding-top: 10px;
    font-size: 1.5em;
  }
`;

const HeroText = styled.p`
  font-size: 1.2em;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

const DownloadButton = styled.a`
  display: inline-block;
  padding: 10px 20px;
  margin-top: 20px;
  background-color: #2980b9;
  color: white;
  font-size: 1.2em;
  border-radius: 5px;
  text-decoration: none;
  &:hover {
    background-color: #1c5985;
  }
  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

const Hero = () => {
  return (
    <HeroSection>
      <HeroContainer>
        <HeroTitle>Manage your important documents and get reminders before they expire.</HeroTitle>
        <div className="app-links">
          <AppLinks />
        </div>
      </HeroContainer>
    </HeroSection>
  );
};

export default Hero;
