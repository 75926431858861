import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logoImage from '../assets/logo.png';

const HeaderContainer = styled.header`
  background: #407885;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
`;

const LogoImage = styled.img`
  width: 40px; // Adjust size as needed
  height: auto;
  margin-right: 10px; // Space between image and text

  @media (max-width: 768px) {
    width: 30px;
    margin-right: 5px;
  }
`;

const LogoText = styled.div`
  font-size: 1.5em;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 1.2em;
  }
`;

const Nav = styled.nav`
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    gap: 10px;
  }
`;

const NavLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-size: 1em;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

const Header = () => {
  return (
    <HeaderContainer>
      <NavLink to="/">
        <Logo>
          <LogoImage src={logoImage} alt="Logo" />
          <LogoText>Doc Minder</LogoText>
        </Logo>
      </NavLink>
      <Nav>
        <NavLink to="/">Home</NavLink>
        <NavLink to="/contact">Contact Us</NavLink>
        <NavLink to="/privacy-policy">Privacy Policy</NavLink>
      </Nav>
    </HeaderContainer>
  );
};

export default Header;
