import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import screenshot1 from '../assets/screenshots/1.png';
import screenshot2 from '../assets/screenshots/2.png';
import screenshot3 from '../assets/screenshots/3.png';
import screenshot4 from '../assets/screenshots/4.png';
import screenshot5 from '../assets/screenshots/5.png';
// import screenshot6 from '../assets/screenshots/6.png';
// import screenshot7 from '../assets/screenshots/7.png';
// import screenshot8 from '../assets/screenshots/8.png';
// import screenshot9 from '../assets/screenshots/9.png';


const ScreenshotsContainer = styled.section`
background: rgb(207, 225, 232);
flex: 1;
display: flex;
align-items: center;
justify-content: center;
color: white;
padding: 20px;
box-sizing: border-box;
min-width: 300px; /* Ensure minimum width for each section */
@media (max-width: 768px) {
  padding: 10px;
}
`;

const ScreenshotTitle = styled.h2`
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #2c3e50;
  @media (max-width: 768px) {
    font-size: 2em;
  }
`;

const ScreenshotImg = styled.img`
  width: 100%;
  max-width: 300px;
  margin: auto;
  @media (max-width: 768px) {
    max-width: 90%;
  }
`;

const SliderContainer = styled.div`
  width: 80%;
  @media (max-width: 768px) {
    width: 90%;
  }
`;

const Screenshots = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <ScreenshotsContainer id="screenshots">
      <SliderContainer>
        <Slider {...settings}>
          <div>
            <ScreenshotImg src={screenshot1} alt="Screenshot 1" />
          </div>
          <div>
            <ScreenshotImg src={screenshot2} alt="Screenshot 2" />
          </div>
          <div>
            <ScreenshotImg src={screenshot3} alt="Screenshot 3" />
          </div>
          <div>
            <ScreenshotImg src={screenshot4} alt="Screenshot 4" />
          </div>
          <div>
            <ScreenshotImg src={screenshot5} alt="Screenshot 5" />
          </div>
          {/* <div>
            <ScreenshotImg src={screenshot6} alt="Screenshot 6" />
          </div> */}
          {/* <div>
            <ScreenshotImg src={screenshot7} alt="Screenshot 7" />
          </div>
          <div>
            <ScreenshotImg src={screenshot8} alt="Screenshot 8" />
          </div>
          <div>
            <ScreenshotImg src={screenshot9} alt="Screenshot 9" />
          </div> */}
        </Slider>
      </SliderContainer>
    </ScreenshotsContainer>
  );
};

export default Screenshots;
