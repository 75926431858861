import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import Hero from './components/Hero';
import ContactUs from './pages/ContactUs';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
import Header from './components/Header';
import Footer from './components/Footer';
import Features from './components/Features';
import Screenshots from './components/Screenshots';
import initializeAnalytics from './analytics';
import './App.css';

initializeAnalytics();


const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const HomeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-items: stretch;
  width: 100%;
  box-sizing: border-box;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  min-height: 100vh;
`;

const App = () => {
  return (
    <Router>
      <Header />
      <MainContainer>
        <Routes>
          <Route path="/" element={
            <>
              <HomeContainer>
                <Hero />
                <Screenshots />
              </HomeContainer>
              <Features />
            </>
          } />
          <Route path="/privacy-policy" element={
            <ContentContainer>
              <PrivacyPolicy />
            </ContentContainer>
          } />
          <Route path="/terms-of-use" element={
            <ContentContainer>
              <TermsOfUse />
            </ContentContainer>
          } />
          <Route path="/contact" element={
            <ContentContainer>
              <ContactUs />
            </ContentContainer>
          } />
        </Routes>
        <Footer />
      </MainContainer>
    </Router>
  );
};

export default App;
