import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background: #407885;
  padding: 20px;
  text-align: center;
  color: white;
`;



const Footer = () => {
    return (
        <FooterContainer>
            &copy; {(new Date().getFullYear())} Doc Minder. All rights reserved.
        </FooterContainer>
    );
};

export default Footer;
